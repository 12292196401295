<template>
	<div class="coachANDathletes">
		<div class="title title-vertical flex-space-between">
			<span>{{ navbarId == 1 ? '金牌教练员' : '金牌运动员' }}</span>
		</div>
		<div class="coachANDathletes-wrap flex-wrap">
			<div class="item flex-direction-column" v-for="item in list" :key="item.id">
				<img :src="item.image"/>
				<span>{{ item.name }}</span>
				<p class="two-line">{{ item.memo }}</p>
			</div>
		</div>
		<Lack
			v-if="list.length == 0"
			text="暂无内容"
			minHeight="260px"
			imgWidth="102px"
			imgHeight="68px"
			:imgSrc="require('@/assets/image/img/15.png')"
		></Lack>
		<div class="pagination">
			<el-pagination
				@current-change="currentChange"
				background
				layout="prev, pager, next"
				:page-size="8"
				:total="total"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script>
import Lack from '@/components/lack/lack.vue'
import {postUserList} from '@/service/api/index'

export default {
	name: 'coachANDathletes',
	props: ['status'],
	components: {Lack},
	data() {
		return {
			navbarId: 1,
			list: [],
			total:0
		}
	},

	mounted() {
		this.navbarId = sessionStorage.getItem('navbarId')
		this.postUserList(1)
	},

	methods: {
		postUserList(page) {
			postUserList({id: this.navbarId, page}).then((res) => {
				this.list = Object.freeze(res.msg.data)
				this.total=res.msg.total
			})
		},
		currentChange(page) {
			this.postUserList(page)
		}
	},
	watch: {
		status(val) {
			if (val == 0) {
				this.navbarId = 1
				this.total=0
				this.postUserList(1)
			} else {
				this.navbarId = 2
				this.total=0
				this.postUserList(1)
			}
		}
	}
}
</script>

<style lang="less" scoped>
/deep/ .el-pager li {
	font-weight: normal;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: #e50114;
	color: #fff;
}

.pagination {
	display: flex;
	justify-content: center;
	margin-top: 29px;
}

.coachANDathletes {
	background-color: #fff;
	border-radius: 3px;
	padding: 30px 0 34px 0;
}

.title-vertical {
	width: 1005px;
}

.coachANDathletes-wrap {
	padding: 0 24px;

	.item {
		margin-top: 22px;
		width: 237px;
		border-radius: 3px;
		margin-right: 19px;
		cursor: pointer;
		transition: all 0.5s;

		&:hover {
			transform: translateY(-10px);
		}

		&:nth-child(4n) {
			margin-right: 0;
		}

		p {
			color: #777777;
			font-size: 14px;
			margin-top: 5px;
			line-height: 25px;
			max-height: 45px;
		}

		img {
			width: 100%;
			height: 326px;
			object-fit: cover;
		}

		span {
			text-align: center;
			height: 25px;
			font-size: 18px;
			line-height: 25px;
			color: #333333;
			margin-top: 11px;
		}
	}
}
</style>
